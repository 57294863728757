import { useContext, useState, useEffect } from "react";
import { useNavigate,useSearchParams } from "react-router-dom";

import { Button, Form, Input, Image, NavBar } from "antd-mobile";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function Login(){
    const nav = useNavigate();
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const [searchParams] = useSearchParams()

    const [verifyCodeId,setVerifyCodeId] = useState(null);
    const [verifyCodeImg,setVerifyCodeImg] = useState(null);

    useEffect(()=>{
        getVerifyCodeImg();
    },[])

    //刷新验证码
    function getVerifyCodeImg(){
        Ajax('/user/getcode','post',{}).then(res=>{
            setVerifyCodeId(res['data']['id']);
            setVerifyCodeImg(res['data']['imgData']);
        })
    }

    //提交表单
    const onFinsih=(vals)=>{
        vals['verifyCodeId']=verifyCodeId;

        Ajax('/user/login','post',vals).then(res=>{
            localStorage.setItem('userToken',res['data']['token']);

            let newGlobalData={...globalData};
            newGlobalData['loginUser']=res['data'];
            setGlobalData(newGlobalData);

            let redirectUrl=searchParams.get('redirectUrl');
            if(redirectUrl){
                nav(redirectUrl);
            }else{
                nav('/');
            }
        });
    }
    return (
        <>
            <NavBar 
                children='用户登陆' 
                back={null} 
                style={{backgroundColor:'#efefef'}}
            />
            <Form
                style={{margin:'30px 0'}}
                mode="card"
                footer={
                    <Button block type='submit' color='primary' size='large'>登陸</Button>
                }
                onFinish={onFinsih}
            >
                <Form.Item name='name' label='用戶名' rules={[{ required: true }]}>
                    <Input placeholder="請輸入用戶名"></Input>
                </Form.Item>
                <Form.Item name='password' label='密碼' rules={[{ required: true }]}>
                    <Input type='password' placeholder="請輸入密碼"></Input>
                </Form.Item>
                <Form.Item 
                    name='verifyCode' 
                    label='验证码' 
                    rules={[{ required: true }]}
                    extra={
                        <Image src={verifyCodeImg} onClick={getVerifyCodeImg} height={35} />
                    }
                >
                    <Input placeholder="請輸入驗證碼"></Input>
                </Form.Item>
            </Form>
        </>
    )
}