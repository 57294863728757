import { createContext, useState } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';

import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';

import Base from '@/pages/base.js';
import Index from '@/pages/index.js';
import OneGirl from '@/pages/onegirl.js';

import Login from '@/pages/login.js';
import Girls from '@/pages/girls.js';
import Golds from '@/pages/golds.js';
import ChangePwd from '@/pages/changepwd.js';

import SysConfig from '@/pages/sysconfig.js';
import Report from '@/pages/report.js';
import Users from '@/pages/users.js';
import Charges from '@/pages/charges.js';
import UserConfig from '@/pages/userconfig.js';
import UserLogs from '@/pages/userlogs.js';
import ShareQrCode from '@/pages/share.js';

//全局状态
export const GlobalContext = createContext(null);

if(process.env.NODE_ENV=='development'){
	require('@/mock/user.js');
    require('@/mock/girl.js');
    require('@/mock/visit.js');
    require('@/mock/upload.js');
    require('@/mock/gold.js');
    require('@/mock/sysconfig.js');
    require('@/mock/userlog.js');
}

export default function App(){
    const [globalData,setGlobalData] = useState({
        'lng':null,
        'lat':null,
        'randNum':0,
        'viewPassword':null,

        'siteConfig':{
            'kfId':null,
            'name':'友家云相册',
            'message':null,
            'citys':{},
            'contacts':[]
        },
        'loginUser':{
            'id':null,
            'token':null,
            'name':null,
            'category':null,
            'gold':null,

            'message':null,
            'girlDayChargeGold':null,
            'girlDayRecommendGold':null,
            'maxRecommendGirl':null
        }
    });
    return (
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
                <GlobalContext.Provider  value={{globalData,setGlobalData}}>
                    <Routes>
                        <Route path='/' element={<Base />}>
                            <Route index element={<Index />} />
                            <Route path='/girls' element={<Girls />} />

                            <Route path='/report' element={<Report />}></Route>
                            <Route path='/userconfig' element={<UserConfig />}></Route>
                            <Route path='/golds' element={<Golds />} />
                            <Route path='/user/changepwd' element={<ChangePwd />} />
                            <Route path='/share' element={<ShareQrCode />} />

                            <Route path='/sysconfig' element={<SysConfig />}></Route>
                            <Route path='/users' element={<Users />}></Route>
                            <Route path='/charges' element={<Charges />}></Route>
                            <Route path='/userlogs' element={<UserLogs />}></Route>

                            <Route path='/user/login' element={<Login />} />
                            <Route path='/girl/:id' element={<OneGirl />} />
                        </Route>
                        <Route path='*' element={<h1>404</h1>}></Route>
                    </Routes>
                </GlobalContext.Provider>
            </ConfigProvider>
        </BrowserRouter>
    );
}