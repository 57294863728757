import { useEffect } from "react";
import { Button, Form, Input, TextArea, Toast, NavBar } from "antd-mobile";

import { Ajax } from '@/api/http.js';

export default function SysConfig(){
    const [form] = Form.useForm();

    useEffect(()=>{
        Ajax('/sysconfig/get','post').then(res=>{
            form.setFieldsValue({
                'closeStartHour':res['data']['closeStartHour'],
                'closeEndHour':res['data']['closeEndHour'],
                'girlDayChargeGold':res['data']['girlDayChargeGold'],
                'girlDayRecommendGold':res['data']['girlDayRecommendGold'],
                'maxRecommendGirl':res['data']['maxRecommendGirl'],
                'userMsg':res['data']['userMsg']
            });
        })
    },[])

    //提交表单
    const onFinsih=(vals)=>{
        Ajax('/sysconfig/edit','post',vals).then(res=>{
            Toast.show({
                icon:'success',
                content:'设置成功！'
            })
        })
    }

    return (
        <>
            <NavBar 
                children='系统设置' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <Form
                form={form}
                style={{margin:'30px 0'}}
                mode="card"
                onFinish={onFinsih}
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>
                }
            >
                <Form.Item label='每天关闭开始时间' name='closeStartHour' rules={[{ required: true }]}>
                    <Input placeholder="比如2"></Input>
                </Form.Item>
                <Form.Item label='每天关闭结束时间' name='closeEndHour' rules={[{ required: true }]}>
                    <Input placeholder="比如10"></Input>
                </Form.Item>
                <Form.Item name='girlDayChargeGold' label='妹子每天金币' rules={[{ required: true }]}>
                    <Input placeholder="请输入每天扣除的金币"></Input>
                </Form.Item>
                <Form.Item name='girlDayRecommendGold' label='妹子每天置顶金币' rules={[{ required: true }]}>
                    <Input placeholder="请输入每天置顶的金币"></Input>
                </Form.Item>
                <Form.Item name='maxRecommendGirl' label='最大置顶人数' rules={[{ required: true }]}>
                    <Input placeholder="每个区域最多置顶几个妹子"></Input>
                </Form.Item>
                <Form.Item name='userMsg' label='会员通知'>
                    <TextArea placeholder="请输入给会员的弹窗通知" rows={10} />
                </Form.Item>
            </Form>
        </>
    )
}