//基础的http请求封装,使用axios进行提交
//post请求(上传文件除外)全部为json

//返回结果全面为json
//处理http状态码错误
//处理返回结果中的1以下状态码
//状态码0：业务错误
//状态码-1：登陆失效

import axios from "axios";
import { Toast } from "antd-mobile";

var baseUrl='';
if(process.env.NODE_ENV=='production'){
    baseUrl=window.location.protocol+'//'+window.location.hostname+'/api';
}

function whenLoading(v){
    if(!v){return;}
    Toast.show({
        icon:'loading',
        duration:0,
        content: '加载中…'
    })
}

function whenSuccess(v,msg){
    Toast.clear();
    if(!v){return;}
    Toast.show({
        icon:'success',
        content:msg
    })
}

function whenError(v,msg){
    Toast.clear();
    if(!v){return;}
    Toast.show({
        icon:'fail',
        content: msg
    })
}

//控制台请求基础函数
function Ajax(url,method,data={},loading=true,success=false,error=true){

    let headers={};

    if(method=='post'){
        headers['Content-Type']='application/json';
    }else if(method=='upload'){
        headers['Content-Type']='multipart/form-data';
    }

    let userToken=localStorage.getItem('userToken');
    if(userToken){
        headers['Authorization']=userToken;
    }
    
    whenLoading(loading);
    
    return new Promise((resolve,reject)=>{
        axios({
            url:url,
            method:method==='upload'?'post':method,
            baseURL:baseUrl,
            headers:headers,
            params:method==='get'?data:'',
            data:method==='get'?'':data,
            responseType:'json'
        }).then(res=>{
            if(res['data']['code']<1){
                //返回1以下的异常状态码
                //whenError(error,res['data']['msg']);
                throw new Error(res['data']['msg']);
            }
            whenSuccess(success,res['data']['msg']);
            return resolve(res['data']);
        }).catch(err=>{
            whenError(error,err.message);
            return reject(err.message);
        })
    })
}


export { Ajax,baseUrl };