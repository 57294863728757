import { NavBar } from 'antd-mobile';
import QRCode  from 'qrcode.react';

export default function ShareQrCode(){
    return (
        <>
            <NavBar 
                children='分享二维码' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div
                style={{width:'300px',margin:'20px auto',textAlign:'center',fontSize:'16px',lineHeight:'25px',color:'red'}}
            >
                <QRCode
                    value={"https://link.zhihu.com/?target=http://"+window.location.hostname}
                    size={300}
                />
                <div>
                    截图后分享给朋友<br />安卓使用浏览器扫一扫<br />苹果保存到相册然后长按识别
                </div>
            </div>
        </>
    )
}