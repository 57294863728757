import { useEffect, useState } from "react";
import { List, DatePicker, Button, NavBar } from 'antd-mobile'

import { Ajax } from '@/api/http.js';

export default function UserLogs(){

    const [listData,setListData] = useState([]);

    const [dpVisible,setDpVisible] = useState(false);
    const [dpValue,setDpValue] = useState(new Date());

    const [dpEndVisible,setDpEndVisible] = useState(false);
    const [dpEndValue,setDpEndValue] = useState(new Date());

    useEffect(()=>{
        getData();
    },[])

    function getData(){
        let dt=dpValue.getFullYear()+'-'+parseInt(dpValue.getMonth()+1)+'-'+dpValue.getDate();
        let dt2=dpEndValue.getFullYear()+'-'+parseInt(dpEndValue.getMonth()+1)+'-'+dpEndValue.getDate();
        Ajax('/userlog/getlist','post',{
            'startDate':dt,
            'endDate':dt2
        }).then(res=>{
            setListData(res['data']);
        })
    }

    return (
        <>
            <NavBar 
                children='操作记录' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{width:'96%',padding:'0 2% 70px 2%'}}>
                <div style={{textAlign:'center',fontSize:'18px',margin:'20px 0 0 0'}}>
                    <strong>日期：</strong>
                    <span onClick={()=>setDpVisible(true)}>{dpValue?.toLocaleDateString()}</span>
                    <span> - </span>
                    <span onClick={()=>setDpEndVisible(true)}>{dpEndValue?.toLocaleDateString()}</span>
                    <Button 
                        color='success'
                        size="small"
                        style={{margin:'0 0 0 10px'}}
                        onClick={getData}
                    >
                        确定
                    </Button>
                </div>
                <DatePicker 
                    title='选择开始日期' 
                    visible={dpVisible}
                    onClose={()=>setDpVisible(false)}
                    onConfirm={(v)=>{
                        setDpValue(v);
                    }}
                />
                <DatePicker 
                    title='选择截止日期' 
                    visible={dpEndVisible}
                    onClose={()=>setDpEndVisible(false)}
                    onConfirm={(v)=>{
                        setDpEndValue(v);
                    }}
                />               
                <List style={{margin:'20px 0'}}>
                    <List.Item 
                        extra={
                            <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>操作时间</div>
                        }
                    >
                        <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>操作人</div>
                    </List.Item>
                    {listData.map((one,index)=>(
                        <List.Item 
                            key={index}
                            title={one.ip}
                            description={one.note}
                            extra={one.createdTime}
                        >
                            {one.userName}
                        </List.Item>
                    ))}
                </List>
            </div>
        </>
    )
}

