import { useContext } from "react";
import { Button, Form, Input, Toast, NavBar } from "antd-mobile";

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function ChangePwd(){
    const { globalData, setGlobalData } = useContext(GlobalContext);

    //提交表单
    const onFinsih=(vals)=>{
        Ajax('/user/changepwd','post',vals).then(res=>{
            localStorage.setItem('userToken',res['token']);

            let newGlobalData={...globalData};
            newGlobalData['loginUser']['token']=res['token'];
            setGlobalData(newGlobalData);

            Toast.show({
                icon:'success',
                content:'修改成功'
            })
        })
    }

    return (
        <>
            <NavBar 
                children='修改密码' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <Form
                style={{margin:'30px 0'}}
                mode="card"
                footer={
                    <Button block type='submit' color='primary' size='large'>確認修改</Button>
                }
                onFinish={onFinsih}
            >
                <Form.Item name='oldPassword' label='原密碼' rules={[{ required: true }]}>
                    <Input type='password' placeholder="請輸入原密碼"></Input>
                </Form.Item>
                <Form.Item name='newPassword' label='新密碼' rules={[{ required: true }]}>
                    <Input type='password' placeholder="請輸入新的密碼"></Input>
                </Form.Item>
            </Form>
        </>
    )
}