import { useEffect, useState,useContext } from "react";
import { Button, Dialog } from "antd-mobile";

import { GlobalContext } from "@/App.js";


//距离按钮,点击显示直线距离
export default function DistanceButton(props){
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const [content,setContent] = useState('点击查看距离');

    useEffect(()=>{
        if(props.lat && props.lng && globalData['lat'] && globalData['lng']){
                //条件满足,计算并显示距离
                let gl=GetDistance( globalData['lat'],globalData['lng'],props.lat,props.lng);
                setContent('距离: '+gl+' 公里');
        }else{
            setContent('点击查看距离');
        }
    },[props.lat,props.lng,globalData['lat'],globalData['lng']])

    //计算2个经纬度之间的直线距离，返回公里数
    function GetDistance(lat1, lng1, lat2, lng2) {
        let radLat1 = lat1 * Math.PI / 180.0;
        let radLat2 = lat2 * Math.PI / 180.0;
        let a = radLat1 - radLat2;
        let b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
        let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
        s = s * 6378.137;
        s = Math.round(s * 10000) / 10000;
        return parseInt(s);  // 单位千米
    }

    //点击按钮,申请获得经纬度,计算并显示距离
    function onClickBtn(){
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showMap,showMapError);
        }else{
            Dialog.alert({
                content:'您的浏览器不支持获得地理位置！'
            })
        }
    }

    function showMap(position){
        let lat1 = position.coords.latitude;
        let lng1 = position.coords.longitude;

        let newGlobalData={...globalData};
        newGlobalData['lat']=lat1;
        newGlobalData['lng']=lng1;
        setGlobalData(newGlobalData);
    }

    function showMapError(err){
        switch(err.code){
            case 1:
                Dialog.alert({
                    content:'您拒絕了位置服務，將無法查看距離，稍候您可以在系統設置中重新允許！'
                });
                break;
            case 2:
                Dialog.alert({
                    content:'無法獲得您的地理位置！'
                });
                break;
            case 3:
                Dialog.alert({
                    content:'獲取您的位置超時！'
                });
                break;
        }
    }

    return (
        <Button block color="success" size="small" onClick={onClickBtn}>{content}</Button>
    )
}