import { useEffect, useState, useContext } from "react";
import { SearchBar, Tabs, Grid, Popup, Divider, Dialog, TextArea, InfiniteScroll, CheckList } from "antd-mobile";
import { DownOutline } from 'antd-mobile-icons'

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

import GirlBox from "@/components/girlbox";
import GirlDetail from "@/components/girldetail.js";

export default function Index(){
    const {globalData} = useContext(GlobalContext);
 
    //参数状态
    const [city,setCity] = useState('上海');
    const [word,setWord] = useState('');
    const [cityOptionsVisible,setCityOptionsVisible] = useState(false);

    //标签页
    const [tabActiveKey,setTabActiveKey] = useState('0');

    //妹子详情
    const [detailVisible,setDetailVisible] = useState(false);
    const [detailData,setDetailData] = useState({});

    useEffect(()=>{
        //3秒后增加访问记录
        setTimeout(AddIndexVisit,3000);

        //3秒后弹出公告
        setTimeout(showDia,3000);
    },[])

    //增加访问记录
    function AddIndexVisit(){
        Ajax('/visit/add','post',{
            'kfId':globalData.siteConfig.kfId,
            'girlId':null
        },false,false,false);
    }

    //弹窗公告,如果已登陆，弹出会员通知(有的话)，如果未登陆，弹出游客通知(有的话)
    function showDia(){
        let content=null;
        if(globalData.loginUser.token){
            content=globalData.loginUser.message;
        }else{
            content=globalData.siteConfig.message;
        }
        if(content){
            Dialog.alert({
                title:<>通知</>,
                content:(
                    <TextArea
                        defaultValue={content}
                        autoSize={true} 
                        readOnly={true}
                        style={{'--font-size':'14px',padding:'0 10px 0 0'}}
                        rows={10}
                    />
                )
            })
        }
    }

    //切换城市
    function onChangeCity(v){
        setCityOptionsVisible(false);
        if(v && v!=city){
            setCity(v);
            setTabActiveKey('0');
        }
    }

    //打开妹子详情窗口
    function onOpenGirlDetail(girlData){
        setDetailVisible(true);
        setDetailData(girlData);
    }

    //关闭妹子详情窗口
    function onCloseGirlDetail(){
        setDetailVisible(false);
    }

    return (
        <>
            <div style={{display:'flex',margin:'10px 2%'}}>
                <div style={{width:'50px',lineHeight:'30px',fontWeight:'bold',fontSize:'14px',textAlign:'center'}} onClick={()=>setCityOptionsVisible(true)}>
                    {city} <DownOutline />
                </div>
                <SearchBar
                    style={{flex:1}}
                    placeholder='输入名称/标签/地址/编号搜索'
                    onSearch={(v)=>setWord(v)}
                    onClear={()=>setWord(null)}
                />
            </div>
            <Tabs activeKey={tabActiveKey} onChange={(k)=>setTabActiveKey(k)}>
                {globalData.siteConfig.citys[city].map((area,index)=>(
                    <Tabs.Tab title={area} key={index}>
                        <Girls city={city} area={area} word={word} onShowGirlDetail={onOpenGirlDetail} />
                    </Tabs.Tab>
                ))}
            </Tabs>
            <Divider></Divider>
            <div style={{textAlign:'center',color:'#777',padding:'0 10% 70px 10%'}}>
                <p>任何人都可以使用此相冊，設置隱私密碼後分享給您喜歡的朋友</p>
                <p>訂製服務請聯繫:+6564151888</p>
                <p>公司地址：25 Serangoon North Avenue 5 #02-01 Keppel DC, 25 Serangoon North Ave 5, SGP1, 554914</p>
                <p>Copyright @2020 Keppel Data Centres Pte Ltd. All rights reserved.</p>
            </div>
            <CityOptions 
                visible={cityOptionsVisible} 
                onClose={()=>setCityOptionsVisible(false)} 
                citys={globalData.siteConfig.citys}
                onChange={(v)=>onChangeCity(v)}
            />
            <Popup
                visible={detailVisible}
                destroyOnClose={true}
                onMaskClick={onCloseGirlDetail}
                position="right"
                bodyStyle={{height:'100vh',width:'100vw',overflowY:'auto'}}
            >
                <GirlDetail data={detailData} onClose={onCloseGirlDetail}></GirlDetail>
            </Popup>
        </>
    )
}


function Girls(props){
    const {globalData} = useContext(GlobalContext);

    const [listData,setListData] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [pageCurrent,setPageCurrent] = useState(1);
    const pageSize=10;

    const [columns,setColumns] = useState(2);

    //根据浏览器宽度设置列数，最低2列
    useEffect(()=>{
        let m = parseInt(window.innerWidth/260);
        if(m<2){m=2};
        setColumns(m);
    },[])

    useEffect(()=>{
        setPageCurrent(1);
        setListData([]);
        setHasMore(true);
    },[props.city,props.area,props.word])

    //加载妹子列表
    async function loadGirls(){
        try {
            const res = await Ajax('/girl/viewlist', 'post', {
                'kfId':globalData.siteConfig.kfId,
                'city':props.city,
                'area': props.area,
                'word': props.word,
                'pageCurrent': pageCurrent,
                'pageSize': pageSize,
                'randNum': globalData.randNum
            });
            setListData(val => [...val, ...res['data']]);
            setHasMore(res['data'].length > 0);
            setPageCurrent(pageCurrent + 1);
        } catch (err) {
            setHasMore(false);
        }
   }

    return (
        <div style={{margin:'10px 0'}}>
            <Grid columns={columns} gap={10}>
                {listData.map((one)=>(
                    <Grid.Item key={one.id}>
                        <GirlBox data={one} onShowGirlDetail={props.onShowGirlDetail} />
                    </Grid.Item>
                ))}
            </Grid>
            <InfiniteScroll loadMore={loadGirls} hasMore={hasMore} threshold={50} />
        </div>
    )
}

function CityOptions(props){
    return (
        <Popup
            visible={props.visible}
            onMaskClick={props.onClose}
            onClose={props.onClose}
            bodyStyle={{ height: '50vh', overflowY:'auto' }}
        >
            <h3 style={{padding:'0 10px'}}>切换城市</h3>
            <CheckList onChange={(v)=>props.onChange(v[0])}>
                {Object.keys(props.citys).map(city=>(
                    <CheckList.Item key={city} value={city}>{city}</CheckList.Item>
                ))}
            </CheckList>
        </Popup>
    )
}