import { useEffect, useState, useRef } from "react";
import { List, Switch, Popup, Button, Space, Dropdown, NavBar, SearchBar, 
    Dialog, Toast, Ellipsis, Radio, ActionSheet, InfiniteScroll, Input, Form
} from 'antd-mobile'
import { AddOutline } from 'antd-mobile-icons';

import { Ajax } from '@/api/http.js';

import User from "@/components/user.js";

export default function Users(){

    //搜索参数
    const dropdownRef = useRef(null);

    const [word,setWord] = useState('');
    const [category,setCategory] = useState(-1);
    const [isEnable,setIsEnable] = useState(-1);

    //列表数据
    const [listData,setListData] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [pageCurrent,setPageCurrent] = useState(1);
    const pageSize = 10;
    const [total,setTotal] = useState(0);

    //动作面板
    const [actionVisible,setActionVisible] = useState(false);
    const [actionId,setActionId] = useState(null);
    
    //新建或修改的对象
    const [objVisible,setObjVisible]=useState(false);
    const [objId,setObjId]=useState({});

    //充值面板参数
    const [chargeVisible,setChargeVisible] = useState(false);

    //搜索
    function onSearch(){
        dropdownRef.current.close();
        setPageCurrent(1);
        setListData([]);
        setHasMore(true);
    }

    //加载用户列表
    function loadMore(){
        return Ajax('/user/getlist','post',{
            'name': word,
            'category': category == -1 ? null : category,
            'isEnable': isEnable == -1 ? null : isEnable,
            'pageCurrent': pageCurrent,
            'pageSize': pageSize
        }).then(res=>{
            setListData(val => [...val, ...res['data']]);
            setHasMore(res['data'].length > 0);
            setTotal(res['total']);
            setPageCurrent(pageCurrent + 1);
        })
    }

    //显示动作列表
    function onShowActions(id){
        setActionId(id);
        setActionVisible(true);
    }

    //充值
    function onCharge(id){
        setActionVisible(false);
        setChargeVisible(true);
    }

    //删除
    async function onDel(id){
        const ru =await Dialog.confirm({
            title:'确认删除',
            content:'您确定要删除吗？'
        });
        if(ru){
            Ajax('/user/del','post',{'id':id}).then(res=>{
                Toast.show({
                    icon:'success',
                    content:'删除成功！',
                    afterClose:()=>{
                        setActionVisible(false);
                        onSearch();
                    }
                })
            })
        }
    }

    //打开单个用户窗口
    function onOpenUser(id){
        setObjId(id);
        setObjVisible(true);
        setActionVisible(false);
    }

    //关闭单个用户窗口
    function onCloseUser(){
        setObjVisible(false);
    }

    return (
        <>
            <NavBar 
                children='用户管理' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{width:'98%',margin:'0 auto'}}>
                <div style={{margin:'20px 2%', display:'flex'}}>
                    <SearchBar
                        style={{width:'100%'}}
                        placeholder="搜索用户名" 
                        value={word}
                        onChange={(v)=>setWord(v)}
                        onSearch={()=>onSearch()}
                        onClear={()=>onSearch()}
                    />
                    <div style={{width:'80px',textAlign:'center'}}>
                        <Button 
                            color='primary' 
                            fill='solid' 
                            size='small'
                            onClick={()=>onOpenUser(null)} 
                        >
                            <AddOutline />
                        </Button>
                    </div>
                </div>
                <Dropdown ref={dropdownRef}>
                    <Dropdown.Item key="category" title='类型'>
                        <Radio.Group value={category} onChange={(v)=>setCategory(v)}>
                            <Space direction="vertical" block style={{margin:'10px 10px'}}>
                                <Radio block value={-1}>所有</Radio>
                                <Radio block value={0}>管理员</Radio>
                                <Radio block value={1}>会员</Radio>
                            </Space>
                        </Radio.Group>
                        <div style={{margin:'0 10px 10px 10px'}}>
                            <Button color='primary' block onClick={()=>onSearch()}>确定</Button>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item key="isEnable" title='状态'>
                        <Radio.Group value={isEnable} onChange={(v)=>setIsEnable(v)}>
                            <Space direction="vertical" block style={{margin:'10px 10px'}}>
                                <Radio block value={-1}>所有</Radio>
                                <Radio block value={1}>正常</Radio>
                                <Radio block value={0}>已禁用</Radio>
                            </Space>
                        </Radio.Group>
                        <div style={{margin:'0 10px 10px 10px'}}>
                            <Button color='primary' block onClick={()=>onSearch()}>确定</Button>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
                <List 
                    header={
                        <>用户管理/{total}条记录</>
                    }
                >
                    {listData.map((one,index)=>(
                        <UserItem 
                            key={index}
                            data={one}
                            onEdit={()=>onShowActions(one['id'])}
                        />
                    ))}                    
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={100}></InfiniteScroll>
                <Popup
                    visible={objVisible}
                    onMaskClick={()=>onCloseUser()}
                    position="right"
                    bodyStyle={{width:'100vw',height:'100vh',overflowY:'auto'}}
                >
                    <User
                        id={objId} 
                        onClose={()=>onCloseUser()} 
                        onReload={onSearch}
                    />
                </Popup>
                <ActionSheet 
                    visible={actionVisible}
                    onClose={()=>setActionVisible(false)}
                    style={{padding:'0 0 20px 0'}}
                    actions={[
                        {
                            text:'修改',
                            key:'edit',
                            onClick:()=>onOpenUser(actionId)
                        },
                        {
                            text:'充值',
                            key:'changeGold',
                            onClick:()=>{
                                onCharge(actionId);
                            }
                        },
                        {
                            text:'删除',
                            key:'del',
                            description: '删除后数据不可恢复',
                            danger: true,
                            bold: true,
                            onClick:()=>{
                                onDel(actionId);
                            }
                        }
                    ]}
                />
                <Charge 
                    id={actionId} 
                    visible={chargeVisible} 
                    onClose={()=>setChargeVisible(false)} 
                    onReload={onSearch} 
                />
            </div>
        </>
    )
}

function UserItem(props){
    const [isEnable,setIsEnable]=useState(null);

    useEffect(()=>{
        setIsEnable(props.data.isEnable);
    },[props.data])

    function onChangeEnable(val){
        Ajax('/user/setenable','post',{'id':props.data.id,'value':val}).then(res=>{
            setIsEnable(val);
        })
    }

    return(
        <List.Item
            title={
                <div onClick={props.onEdit}>
                    {props.data.category==0 ? '系统管理员' : '会员'}
                </div>
            }
            description={
                <>金币:{props.data.gold}, 妹子:{props.data.girlAmount}<br />{props.data.createdTime}</>
            }
            extra={
                <Switch 
                    checkedText='正常' 
                    uncheckedText='禁用' 
                    checked={isEnable} 
                    onChange={(val)=>onChangeEnable(val)} 
                />
            }
            clickable
            arrow={false}
        >
            <div onClick={props.onEdit}>
                <Ellipsis content={props.data.name} />
            </div>
        </List.Item>
    )
}

function Charge(props){
    const [form]=Form.useForm();

    //提交表单
    function onFinish(vals){
        vals['id']=props.id;

        Ajax('/user/changegold','post',vals).then(res=>{
            props.onClose();
            Toast.show({
                icon:'success',
                content:'充值成功！',
                afterClose:()=>{
                    form.resetFields();
                    props.onReload();
                }
            })
        })
    }

    return (
        <Dialog
            visible={props.visible}
            actions={[
                [{
                    key:'cancel',
                    text:'取消',
                    onClick:props.onClose
                },
                {
                    key:'ok',
                    text:'确定',
                    onClick:form.submit
                }]
            ]}
            content={(
                <Form
                    form={form}
                    mode="card"
                    onFinish={onFinish}
                >
                    <Form.Item label='充值金额' name='money'>
                        <Input placeholder="钱钱钱！不要填错"></Input>
                    </Form.Item>
                    <Form.Item label='金币数量' name='amount' rules={[{ required: true }]}>
                        <Input placeholder="金币数量"></Input>
                    </Form.Item>
                    <Form.Item label='备注' name='note'>
                        <Input placeholder="备注说明"></Input>
                    </Form.Item>
                </Form>
            )}
        />
    )
}