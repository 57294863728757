import { useEffect, useState,useRef,useContext } from "react";
import { List, Switch, Popup, Button, Space, Image, Dropdown, NavBar,
    SearchBar, Dialog, Toast, Ellipsis, Radio, ActionSheet, InfiniteScroll, Tag
} from 'antd-mobile'
import { AddOutline } from 'antd-mobile-icons';

import { Ajax } from '@/api/http.js';
import Girl from "@/components/girl.js";
import { GlobalContext } from "@/App.js";

export default function Girls(){
    const {globalData,setGlobalData} = useContext(GlobalContext);

    //搜索参数
    const dropdownRef = useRef(null);

    const [createrName,setCreaterName] = useState('');
    const [word,setWord] = useState('');
    const [city,setCity] = useState(-1);
    const [isWork,setIsWork] = useState(-1);
    const [isReal,setIsReal] = useState(-1);

    //列表数据
    const [listData,setListData] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [pageCurrent,setPageCurrent] = useState(1);
    const pageSize = 10;
    const [total,setTotal] = useState(0);

    //动作面板
    const [actionVisible,setActionVisible] = useState(false);
    const [actionId,setActionId] = useState(null);
    const [actionIsReal,setActionIsReal] = useState(false);
    const [actionIsRecommend,setActionIsRecommend] = useState(false);
    
    //新建或修改的对象
    const [objVisible,setObjVisible]=useState(false);
    const [objId,setObjId] = useState(null);

    //搜索
    function onSearch(){
        dropdownRef.current.close();
        setPageCurrent(1);
        setListData([]);
        setHasMore(true);
    }

    //加载妹子列表
    function loadGirls(){
         return Ajax('/girl/getlist', 'post', {
            createrName: createrName,
            word: word,
            city: city == -1 ? null : city,
            isWork: isWork == -1 ? null : isWork,
            isReal: isReal == -1 ? null : isReal,
            pageCurrent: pageCurrent,
            pageSize: pageSize
        }).then(res=>{
            setListData(val => [...val, ...res['data']]);
            setHasMore(res['data'].length > 0);
            setTotal(res['total']);
            setPageCurrent(pageCurrent + 1);
        }).catch(err=>{
            setHasMore(false);
        })
    }


    //显示动作列表
    function onShowActions(id,isReal,isRecommend){
        setActionId(id);
        setActionIsReal(isReal);
        setActionIsRecommend(isRecommend);
        setActionVisible(true);
    }

    //关闭动作列表
    function onCloseActions(){
        setActionVisible(false);
    }

    //修改上班状态
    function onChangeWork(gold){
        let tmpGlobalData={...globalData};
        tmpGlobalData['loginUser']['gold']=gold;
        setGlobalData(tmpGlobalData);
    }

    //新建
    function onAdd(){
        setObjId(null);
        setObjVisible(true);
    }

    //修改
    function onEdit(){
        setActionVisible(false);
        setObjId(actionId);
        setObjVisible(true);
    }

    //关闭单个妹子窗口
    function onCloseGirl(){
        setObjVisible(false);
    }

    return (
        <>
            <NavBar 
                children='相册管理' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{backgroundColor:'#efefef',padding:'10px 15px',borderTop:'1px solid #ddd'}}>
                <ul style={{margin:'0 0 0 0',padding:'0 0 0 15px',lineHeight:'1.5em'}}>
                    <li>当前剩余金币：<font color='red'>{globalData.loginUser.gold}</font></li>
                    <li>上班的妹子每天自动扣除<font color='red'> {globalData.loginUser.girlDayChargeGold} </font>个金币，休息的不会扣，若金币不足第2天会自动变成休息状态;</li>
                    <li>如果需要给妹子加上"已认证"标志，请拍摄视频后发给平台客服，视频要求：妹子念“当天日期，我在友家等你”;</li>
                    <li>已认证的妹子可开启置顶功能，每天置顶需要<font color='red'> {globalData.loginUser.girlDayRecommendGold} </font>个金币，开启后会置顶到当天晚上12点，12点过后会自动关闭置顶。</li>
                    <li>每个区最多只能置顶<font color='red'> {globalData.loginUser.maxRecommendGirl} </font>个妹子，先开先得，未置顶妹子随机排序。</li>
                </ul>
            </div>
            <div style={{width:'98%',margin:'0 auto',overflow:'hidden'}}>
                <div style={{margin:'20px 2%', display:'flex'}}>
                    {globalData.loginUser.category==0 &&
                        <SearchBar 
                            style={{marginRight:'10px'}} 
                            placeholder="创建人" 
                            value={createrName}
                            onChange={(v)=>setCreaterName(v)}
                            onSearch={()=>onSearch()}
                            onClear={()=>onSearch()}
                        />
                    }
                    <SearchBar
                        style={{width:'100%'}}
                        placeholder="搜索名称/地址/备注" 
                        value={word}
                        onChange={(v)=>setWord(v)}
                        onSearch={()=>onSearch()}
                        onClear={()=>onSearch()}
                    />
                    <div style={{width:'80px',textAlign:'center'}}>
                        <Button 
                            color='primary' 
                            fill='solid' 
                            size='small'
                            onClick={onAdd} 
                        >
                            <AddOutline />
                        </Button>
                    </div>
                </div>
                <Dropdown ref={dropdownRef}>
                    <Dropdown.Item key="city" title='城市'>
                        <div style={{maxHeight:'300px',overflowY:'auto'}}>
                            <Radio.Group value={city} onChange={(v)=>setCity(v)}>
                                <Space direction='vertical' block style={{margin:'10px 10px'}}>
                                    <Radio block value={-1}>所有</Radio>
                                    {Object.keys(globalData.siteConfig.citys).map((one,index)=>(
                                        <Radio block value={one} key={index}>{one}</Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </div>
                        <div style={{margin:'0 10px 10px 10px'}}>
                            <Button color='primary' block onClick={()=>onSearch()}>確定</Button>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item key="isReal" title='认证'>
                        <Radio.Group value={isReal} onChange={(v)=>setIsReal(v)}>
                            <Space direction="vertical" block style={{margin:'10px 10px'}}>
                                <Radio block value={-1}>所有</Radio>
                                <Radio block value={1}>已认证</Radio>
                                <Radio block value={0}>未认证</Radio>
                            </Space>
                        </Radio.Group>
                        <div style={{margin:'0 10px 10px 10px'}}>
                            <Button color='primary' block onClick={()=>onSearch()}>確定</Button>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item key="isWork" title='上班'>
                        <Radio.Group value={isWork} onChange={(v)=>setIsWork(v)}>
                            <Space direction="vertical" block style={{margin:'10px 10px'}}>
                                <Radio block value={-1}>所有</Radio>
                                <Radio block value={1}>上班中</Radio>
                                <Radio block value={0}>休假中</Radio>
                            </Space>
                        </Radio.Group>
                        <div style={{margin:'0 10px 10px 10px'}}>
                            <Button color='primary' block onClick={()=>onSearch()}>確定</Button>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
                <List 
                    header={
                        <>总共{total}个妹子</>
                    }
                    style={{marginBottom:'30px'}}
                >
                    {listData.map((one,index)=>(
                        <GirlItem 
                            key={index} 
                            data={one} 
                            onShowActions={()=>onShowActions(one.id,one.isReal,one.isRecommend)}
                            onChangeWork={(gold)=>onChangeWork(gold)}
                        />
                    ))}                    
                </List>
                <InfiniteScroll loadMore={loadGirls} hasMore={hasMore} threshold={100}></InfiniteScroll>
                <Popup
                    visible={objVisible}
                    onMaskClick={onCloseGirl}
                    position="right"
                    bodyStyle={{width:'100vw',height:'100vh',overflowY:'auto'}}
                >
                    <Girl
                        id={objId} 
                        onClose={onCloseGirl} 
                        onReload={onSearch} 
                    />
                </Popup>
                <DoActions 
                    visible={actionVisible}
                    id={actionId}
                    isReal={actionIsReal}
                    isRecommend={actionIsRecommend}
                    onClose={onCloseActions}
                    onEdit={onEdit}
                    onReload={onSearch}
                />
            </div>
        </>
    )
}

function GirlItem(props){
    const [isWork,setIsWork]=useState(null);

    useEffect(()=>{
        setIsWork(props.data.isWork);
    },[props.data])

    function onChangeWork(val){
        Ajax('/girl/setwork','post',{'id':props.data.id,'value':val}).then(res=>{
            setIsWork(val);
            props.onChangeWork(res['gold']);
        })
    }

    return(
        <List.Item
            prefix={
                <Image 
                    src={props.data.logo}
                    style={{borderRadius:20}}
                    fit='cover'
                    width={40}
                    height={40}
                    lazy={true}
                />
            }
            title={
                <div onClick={props.onEdit}>
                    {props.data.price}
                </div>
            }
            description={
                <Space direction="vertical">
                    {props.data.area+'-'+props.data.address}
                    <Space>
                        {props.data.isReal ? (
                            <Tag color="danger">已认证</Tag>
                        ) : (
                            <Tag color="default">未认证</Tag>
                        )}
                        {props.data.isRecommend ? (
                            <Tag color="danger">已置顶</Tag>
                        ) : (
                            <Tag color="default">未置顶</Tag>
                        )}
                    </Space>
                    {props.data.createrName && 
                        <>创建人:{props.data.createrName}</>
                    }
                </Space>
            }
            extra={
                <Switch checkedText='上班' uncheckedText='休息' checked={isWork} onChange={(val)=>onChangeWork(val)} />
            }
            clickable
            arrow={false}
        >
            <div onClick={props.onShowActions}>
                <Ellipsis content={props.data.name} />
            </div>
        </List.Item>
    )
}

function DoActions(props){
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const baseOptions=[
        {
            text:'查看',
            key:'link',
            onClick:()=>{
                window.open('/girl/'+props.id,'_blank');
                props.onClose();
            }
        },
        {
            text:'修改',
            key:'edit',
            onClick:()=>{
                props.onEdit();
            }
        },
        {
            text:'刪除',
            key:'del',
            description: '删除后数据不可恢复',
            danger: true,
            bold: true,
            onClick:()=>{
                onDel();
            }
        }
    ];
    const [options,setOptions] = useState([]);
    useEffect(()=>{
        let tmpOptions=[...baseOptions];
        if(props.isRecommend){
            tmpOptions.splice(2,0,{
                text:'关闭置顶',
                key:'unrecommend',
                onClick:()=>{
                    onSetRecommend(0);
                }
            })
        }else{
            tmpOptions.splice(2,0,{
                text:'开启置顶',
                key:'torecommend',
                onClick:()=>{
                    onSetRecommend(1);
                }
            })
        }

        if(globalData.loginUser.category==0){
            if(props.isReal){
                tmpOptions.splice(3,0,{
                    text:'取消认证',
                    key:'noreal',
                    onClick:()=>{
                        onSetReal(0);
                    }
                })
            }else{
                tmpOptions.splice(3,0,{
                    text:'设置为已认证',
                    key:'toreal',
                    onClick:()=>{
                        onSetReal(1);
                    }
                })
            }
        }
        setOptions(tmpOptions);
    },[globalData.loginUser.category,props.id,props.isReal,props.isRecommend])

    //认证
    async function onSetReal(isReal){
        const ru =await Dialog.confirm({
            title:'请确认',
            content:'您确定要修改认证状态吗？'
        });
        if(ru){
            Ajax('/girl/setreal','post',{'id':props.id,'value':isReal}).then(res=>{
                Toast.show({
                    icon:'success',
                    content:'设置成功！',
                    afterClose:()=>{
                        props.onClose();
                        props.onReload();
                    }
                })
            })
        }
    }

    //置顶
    async function onSetRecommend(isRecommend){
        let content='';
        if(isRecommend){
            content='您确定要开启置顶吗？开启后将扣除'+globalData.loginUser.girlDayRecommendGold+'个金币。';
        }else{
            content='您确定要关闭置顶吗？';
        }
        const ru =await Dialog.confirm({
            title:'请确认',
            content:<>{content}</>
        });

        if(ru){
            Ajax('/girl/setrecommend','post',{'id':props.id,'value':isRecommend}).then(res=>{
                Toast.show({
                    icon:'success',
                    content:'设置成功！',
                    afterClose:()=>{
                        props.onClose();
                        props.onReload();
                    }
                })

                let tmpGlobalData={...globalData};
                tmpGlobalData['loginUser']['gold']=res['gold'];
                setGlobalData(tmpGlobalData);
            })
        }
    }

    //删除
    async function onDel(){
        const ru =await Dialog.confirm({
            title:'确认删除',
            content:'您确定要删除吗？'
        });
        if(ru){
            Ajax('/girl/del','post',{'id':props.id}).then(res=>{
                Toast.show({
                    icon:'success',
                    content:'刪除成功！',
                    afterClose:()=>{
                        props.onClose();
                        props.onReload();
                    }
                })
            })
        }
    }

    return (
        <ActionSheet 
            visible={props.visible}
            onClose={props.onClose}
            actions={options}
            style={{padding:'0 0 20px 0'}}
        />
    )
}