import { useState, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Input, Button, Dialog } from "antd-mobile";
import { LockOutline } from 'antd-mobile-icons';

import { useTitle } from "ahooks";

import { Ajax } from "@/api/http.js";
import { GlobalContext } from "@/App.js";

import TopBar from "@/components/topbar.js";

export default function Base(){

    const [isWxBrowser,setIsWxBrowser] = useState(false);
    const [isVerifyed,setIsVerifyed] = useState(false);

    const [siteName,setSiteName] = useState('友家云相册');
    useTitle(siteName);

    useEffect(()=>{
        checkBrowser();
    },[])

    function checkBrowser(){
        //检查是否微信浏览器
        const userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.includes('micromessenger') || userAgent.includes('wechat')){
            setIsWxBrowser(true);
        }else{
            setIsWxBrowser(false);
        }
    }

    if(isWxBrowser){
        return <WxBrowserMsg />
    }

    if(isVerifyed){
        return (
            <>
                <TopBar />
                <Outlet />
            </>
        )
    }

    return (
        <EnterViewPassword setIsVerifyed={setIsVerifyed} setSiteName={setSiteName} />
    )
}


function WxBrowserMsg(){
    return (
        <h1>请点击右上角，选择在默认浏览器中打开</h1>
    )
}

function EnterViewPassword(props){
    const {setGlobalData} = useContext(GlobalContext);
    const [viewPassword,setViewPassword] = useState('');

    //使用查看密码去获得站点配置
    function onGetSiteConfig(){
        if(!viewPassword){
            Dialog.alert({content:'请输入邀请码！'});
            return;
        }
        Ajax('/user/getsiteconfig','post',{
            'viewPassword':viewPassword
        },true,false,true).then(res=>{
            setGlobalData({
                'lng':null,
                'lat':null,
                'randNum':Math.floor(Math.random()*(100000)),
                'viewPassword':viewPassword,

                'siteConfig':res['data']['siteConfig'],
                'loginUser':res['data']['loginUser']
            })

            props.setSiteName(res['data']['siteConfig']['name']);
            props.setIsVerifyed(true);

        }).catch(err=>{
            props.setIsVerifyed(false);
            console.log(err);
        })
    }


    return (
        <div style={{textAlign:'center',margin:'20vh 20vw'}}>
            <LockOutline color="#444" fontSize={120} />
            <div style={{fontSize:'24px'}}>请输入邀请码</div>
            <Input
                placeholder="请输入邀请码" 
                style={{border:'1px solid #000',padding:'2px 10px',margin:'15px 0'}}
                value={viewPassword}
                onChange={(v)=>setViewPassword(v)}
                onEnterPress={()=>onGetSiteConfig()}
            />
            <Button
                color="primary"
                style={{width:'120px'}}
                onClick={()=>onGetSiteConfig()}
            >
                确定
            </Button>
        </div>
    )
}