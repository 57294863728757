import { useEffect, useState, useContext } from "react";
import { Form, Input, Button, ImageUploader, NavBar, Toast, Selector, TextArea, Cascader} from 'antd-mobile';
import { Ajax } from '@/api/http.js';

import { GlobalContext } from "@/App.js";

export default function Girl(props){
    const {globalData} = useContext(GlobalContext);

    const [form]=Form.useForm();
    const [title,setTitle]=useState('');

    //设置区域列表
    const [cityOptions,setCityOptions] = useState([]);
    const [cityOptionsVisible,setCityOptionsVisible] = useState(false);

    //部分表单参数
    const [logoFileList,setLogoFileList]=useState([]);
    const [imgsFileList,setImgsFileList]=useState([]);
    const [videosFileList,setVideosFileList] = useState([]);
    const [realVideoFileList,setRealVideoFileList] = useState([]);

    useEffect(()=>{
        //阻止浏览器后退
        window.history.pushState(null, null, document.URL);
        window.onpopstate = function (e) {
            window.history.pushState(null, null, document.URL);
            props.onClose();
        };
        return () => {
            window.onpopstate = null;
        };
    },[])

    useEffect(()=>{
        //设置区域列表
        let tmpOpts=[];
        for(let city in globalData.siteConfig.citys){
            let item={
                'label':city,
                'value':city,
                'children':[]
            }
            for(let area of globalData.siteConfig.citys[city]){
                item['children'].push({
                    'label':area,
                    'value':area
                })
            }
            tmpOpts.push(item);
        }
        setCityOptions(tmpOpts);
    },[])

    //修改区域后手动修改form的值
    function onChangeCityArea(v){
        form.setFieldsValue({'cityArea':v});
    }

    //加载妹子数据
    useEffect(()=>{
        if(props.id){
            Ajax('/girl/get','post',{'id':props.id}).then(res=>{
                setTitle(res['data']['name']);

                //联系方式返回字段可能不全
                form.resetFields();

                form.setFieldsValue({
                    'cityArea':[res['data']['city'],res['data']['area']],
                    'name':res['data']['name'],
                    'age':res['data']['age'],
                    'height':res['data']['height'],
                    'weight':res['data']['weight'],
                    'bust':res['data']['bust'],
                    'tags':res['data']['tags'],
                    
                    'address':res['data']['address'],
                    'price':res['data']['price'],
                    'note':res['data']['note'],
                    'phone':res['data']['phone']
                });

                setLogoFileList([{'url':res['data']['logo']}]);
                let ls=[];
                for(let i of res['data']['imgs']){
                    ls.push({
                        'url':i
                    })
                }
                setImgsFileList(ls);
                let vs=[];
                for(let v of res['data']['videos']){
                    vs.push({
                        'url':v['url'],
                        'thumbnailUrl':v['thumbnailUrl']
                    })
                }
                setVideosFileList(vs);
                let vs2=[];
                for(let v of res['data']['realVideos']){
                    vs2.push({
                        'url':v['url'],
                        'thumbnailUrl':v['thumbnailUrl']
                    })
                }
                setRealVideoFileList(vs2);
            })
        }else{
            setTitle('新的妹子');
            form.resetFields();
            setLogoFileList([]);
            setImgsFileList([]);
            setVideosFileList([]);
            setRealVideoFileList([]);
        }
    },[props.id])

    //上传封面
    async function uploadLogo(fl){
        return Ajax('/upload/logo','upload',{file:fl});
    }

    //上传详情图
    async function uploadImg(fl){
        for(let i=0; i<100000; i++){
            //暂停一会，不然有些传不上去，奇怪
        }
        return Ajax('/upload/img','upload',{file:fl});
    }

    //上传视频
    async function uploadVideo(fl){
        for(let i=0; i<100000; i++){
            //暂停一会，不然有些传不上去，奇怪
        }
        return Ajax('/upload/video','upload',{file:fl});
    }

    //提交表单
    function onFinish(vals){
        let url='/girl/add';
        if(props.id){
            url='/girl/edit';
            vals['id']=props.id;
        }
        if(vals['cityArea'].length==2){
            vals['city']=vals['cityArea'][0];
            vals['area']=vals['cityArea'][1];
        }else{
            Toast.show({
                icon:'fail',
                content:'请选择城市和区域'
            })
            return;
        }

        if(logoFileList.length<1){
            Toast.show({
                icon:'fail',
                content:'请上传封面图片'
            })
            return;
        }

        vals['logo']=logoFileList[0]['url'];
        vals['imgs']=[];
        for(let i of imgsFileList){
            vals['imgs'].push(i['url']);
        }
        vals['videos']=[];
        for(let i of videosFileList){
            vals['videos'].push(
                {
                    'url':i['url'],
                    'thumbnailUrl':i['thumbnailUrl']
                }
            );
        }
        vals['realVideos']=[];
        for(let i of realVideoFileList){
            vals['realVideos'].push(
                {
                    'url':i['url'],
                    'thumbnailUrl':i['thumbnailUrl']
                }
            );
        }
        Ajax(url,'post',vals).then(res=>{
            if(!props.id){
                form.resetFields();
                setLogoFileList([]);
                setImgsFileList([]);
                setVideosFileList([]);
                setRealVideoFileList([]);
            }
            props.onClose();
            props.onReload();
        })
    }

    return (
        <>
            <NavBar
                onBack={props.onClose}
                style={{position:'sticky',top:'0',zIndex:9999,height:'50px',backgroundColor:'#efefef'}}
                right={
                    <Button color='success' size='small' onClick={()=>form.submit()}>保存</Button>
                }
            >
                {title}
            </NavBar>
            <Form
                form={form}
                mode="card"
                onFinish={onFinish}
                style={{margin:'0 0 130px 0'}}
            >
                <Form.Item 
                    label='城市和区域' 
                    name='cityArea'
                    rules={[{ required: true }]}
                    extra={<Button size='small' color='primary' onClick={()=>setCityOptionsVisible(true)}>请选择</Button>}
                >
                    <Cascader 
                        options={cityOptions}
                        visible={cityOptionsVisible}
                        onClose={()=>setCityOptionsVisible(false)}
                        onConfirm={onChangeCityArea}
                    >
                        {items => {
                            if (items.every(item => item === null)) {
                                return '未选择'
                            } else {
                                return items.map(item => item?.label ?? '未选择').join('-')
                            }
                        }}
                    </Cascader>
                </Form.Item>
                <Form.Item label='名称' name='name' rules={[{ required: true }]}>
                    <Input placeholder="区域+名称，例如浦东娜娜"></Input>
                </Form.Item>
                
                <Form.Header />
                <Form.Item label='年龄' name='age'>
                    <Input placeholder="年龄或出生日期"></Input>
                </Form.Item>
                <Form.Item label='身高' name='height'>
                    <Input placeholder="身高，例如170"></Input>
                </Form.Item>
                <Form.Item label='体重' name='weight'>
                    <Input placeholder="体重，例如50kg"></Input>
                </Form.Item>
                <Form.Item label='胸围' name='bust'>
                    <Input placeholder="胸围，例如C"></Input>
                </Form.Item>
                <Form.Item label='标签' name='tags'>
                    <Selector
                        multiple={true}
                        options={[
                            {label:'舌吻',value:'舌吻'},
                            {label:'无套',value:'无套'},
                            {label:'特服',value:'特服'},
                            {label:'SM',value:'SM'},
                            {label:'可外',value:'可外'},
                            {label:'可夜',value:'可夜'},
                            {label:'水磨',value:'水磨'}
                        ]}
                    ></Selector>
                </Form.Item>

                <Form.Header />
                <Form.Item label='地址(小区名称)' name='address' rules={[{ required: true }]}>
                    <Input placeholder="小区名称，仅客服可见"></Input>
                </Form.Item>
                <Form.Header />
                <Form.Item label='价格(给客服的价格)' name='price' rules={[{ required: true }]}>
                    <Input placeholder="给客服的报价，仅客服可见，例如1300"></Input>
                </Form.Item>
                {globalData.siteConfig.contacts.map((one,index)=>(
                    <Form.Item label={'联系方式（'+one+'）'} key={index} name={['phone',one]}>
                        <Input placeholder={one+'，没有不要填'} />
                    </Form.Item>
                ))}

                <Form.Item label='备注信息(给客服看的)' name='note'>
                    <TextArea rows={2} placeholder="备注信息,仅客服可见"></TextArea>
                </Form.Item>
                
                <Form.Header />
                <Form.Item label='封面'>
                    <ImageUploader
                        maxCount={1}
                        value={logoFileList}
                        onChange={setLogoFileList}
                        upload={uploadLogo}
                        accept="image/*"
                    />
                </Form.Item>

                <Form.Item label='详情'>
                    <ImageUploader
                        multiple={true}
                        maxCount={9}
                        value={imgsFileList}
                        onChange={setImgsFileList}
                        upload={uploadImg}
                        accept="image/*"
                    />
                </Form.Item>

                <Form.Item label='视频'>
                    <ImageUploader
                        multiple={true}
                        maxCount={4}
                        value={videosFileList}
                        onChange={setVideosFileList}
                        upload={uploadVideo}
                        accept="video/*"
                    />
                </Form.Item>

                {globalData.loginUser.category==0 && (
                    <Form.Item label='验证视频'>
                    <ImageUploader
                        multiple={true}
                        maxCount={4}
                        value={realVideoFileList}
                        onChange={setRealVideoFileList}
                        upload={uploadVideo}
                        accept="video/*"
                    />
                </Form.Item>
                )}
            </Form>
        </>
    )
}