import { useState,useContext } from "react";
import { List, InfiniteScroll, NavBar, Card } from 'antd-mobile'

import { Ajax } from '@/api/http.js';
import { GlobalContext } from "@/App.js";

export default function Golds(){
    const {globalData,setGlobalData} = useContext(GlobalContext);

    const [listData,setListData] = useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [pageCurrent,setPageCurrent] = useState(1);
    const pageSize =10;

    //加载金币变化列表
    function loadMore(){
        return Ajax('/gold/getlist', 'post', {
           pageCurrent: pageCurrent,
           pageSize: pageSize
       }).then(res=>{
           setListData(val => [...val, ...res['data']]);
           setHasMore(res['data'].length > 0);
           setPageCurrent(pageCurrent + 1);

           let tmpGlobalData={...globalData};
           tmpGlobalData['loginUser']['gold']=res['gold'];
           setGlobalData(tmpGlobalData);
       }).catch(err=>{
           setHasMore(false);
       })
   }

    return (
        <>
            <NavBar 
                children='我的金币' 
                back={null} 
                style={{backgroundColor:'#efefef',marginBottom:'0'}}
            />
            <div style={{width:'96%',padding:'20px 2%'}}>
                <Card title='当前剩余金币' style={{backgroundColor:'#ececec'}}>
                <div style={{fontSize:'25px',color:'green'}}>{globalData.loginUser.gold}</div>
                </Card>
                <List style={{margin:'20px 0'}}>
                    <List.Item 
                        extra={
                            <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>数量</div>
                        }
                    >
                        <div style={{fontSize:'16px',fontWeight:'bold',color:'green'}}>说明</div>
                    </List.Item>
                    {listData.map((one)=>(
                        <List.Item 
                            key={one.id}
                            extra={one.amount}
                            description={one.createdTime}
                        >
                            {one.note}
                        </List.Item>
                    ))}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={100}></InfiniteScroll>
            </div>
        </>
    )
}

